// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bildene-js": () => import("./../../../src/pages/bildene.js" /* webpackChunkName: "component---src-pages-bildene-js" */),
  "component---src-pages-bilder-js": () => import("./../../../src/pages/bilder.js" /* webpackChunkName: "component---src-pages-bilder-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-pages-videoen-js": () => import("./../../../src/pages/videoen.js" /* webpackChunkName: "component---src-pages-videoen-js" */)
}

